<!-- <div *ngIf="cookieConsent === ''" id="cookies-consent" class="cookie-consent-banner">
  <div class="alert cookie-consent-message fade show">
    <div>
      <div class="cookies-title">This website uses cookies</div>
      <p >
        We use cookies to personalize content and to analyze our traffic. Tell us if you wish to consent to our use of
        cookies.
        For more information, please see our <a routerLink="/tos">Terms of Service</a> and <a target="_blank"
          href="https://www.heart.org/en/about-us/statements-and-policies/privacy-statement">Privacy Policy</a></p>
      <div >
        <button class="btn btn-primary primary-btn cookie-btn" (click)="optOut();" id="cookiesOptOutBtn" type="button">REVIEW
          COOKIE SETTINGS</button>
        <button class="btn btn-primary primary-btn cookie-btn" (click)="acceptCookies();" 
          id="cookiesOptInBtn" type="button">I CONSENT</button>
      </div>
    </div>

  </div>
</div> -->

<app-upload-progress-alert></app-upload-progress-alert>
<app-refresh-token-alert></app-refresh-token-alert>
<app-system-failure></app-system-failure>

<!-- header.component.html -->
<!-- header.component.html -->
<!-- header.component.html -->
<!-- header.component.html -->
<!-- header.component.html -->
<!-- header.component.html -->
<!-- header.component.html -->
<header [class.position-absolute]="isHomePage">

  <nav class="navbar navbar-expand-lg navbar-light bg-light" style="padding: 1rem !important;">
    <a class="navbar-brand" routerLink="/">
      <img [src]="showVideo ? 'assets/images/logo/aha-pmp-logo-white.png' : 'assets/images/logo/aha-pmp-logo.png'" alt="Logo"
        class="aha-logo" />
    </a>
    <button class="navbar-toggler navbar-light" type="button" data-bs-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse aha-nav" id="navbarNav">
      <ul class="navbar-nav d-flex align-items-center ml-auto mr-auto">
        <li class="nav-item" [ngClass]="{'show': isAboutMenuOpen}" style="position: relative;">
          <a [ngStyle]="{'color': linkColor}" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
             (click)="toggleAboutMenu()" aria-haspopup="true" aria-expanded="false">About</a>
          <div class="dropdown-menu" [ngClass]="{'show': isAboutMenuOpen}" aria-labelledby="navbarDropdown" style="width: max-content;">
            <a class="dropdown-item" id="background-history" routerLink="/background-history">Background & History</a>
            <a class="dropdown-item" id="tos-submenu" routerLink="/tos">Terms of Service</a>
            <a class="dropdown-item" id="si-submenu" routerLink="/security-information">Security</a>
          </div>
        </li>
        <li class="nav-item" [ngClass]="{'show': isUserFeatureOpen}" style="position: relative;">
          <a [ngStyle]="{'color': linkColor}" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
             (click)="toggleUserFeatureMenu()" aria-haspopup="true" aria-expanded="false">Uses & Features</a>
          <div class="dropdown-menu" [ngClass]="{'show': isUserFeatureOpen}" aria-labelledby="navbarDropdown" style="width: max-content;">
            <a class="dropdown-item" id="pricing" routerLink="/pricing">Uses & Pricing</a>
            <a class="dropdown-item" id="tos" routerLink="/tools">Tools & Features</a>
          </div>
        </li>
        <li class="nav-item" >
          <a [ngStyle]="{'color': linkColor}" routerLink="/workspace/launch-workspace" routerLinkActive="active-link" *ngIf="isLoggedIn()" class="nav-link">Workspace</a>
          <a [ngStyle]="{'color': linkColor}" routerLink="/workspace" routerLinkActive="active-link" *ngIf="!isLoggedIn()" class="nav-link">Workspace</a>
        </li>
        <li class="nav-item d-none d-lg-block">
          <a class="nav-link center-logo" href="#">
            <img
              [src]="showVideo ? 'assets/images/precision-medicine-platform-white-logo.png' : 'assets/images/precision-medicine-platform-logo.png' "
              alt="Center Logo" width="160" height="75" />
          </a>
        </li>

        <li class="nav-item" >
          
          <!-- <a routerLinkActive="active-link" [ngStyle]="{'color': linkColor}" *ngIf="isLoggedIn()" 
          class="nav-link dropdown-toggle" id="navbarDataDropdown" role="button"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Data</a>

            <div class="dropdown-menu" aria-labelledby="navbarDataDropdown">
              
              <a class="dropdown-item" id="explore-request" routerLink="/request/loggedin">Explore & Request</a>
              <a class="dropdown-item" id="data-documentation" routerLink="/request/data/loggedin">Data Documentation</a>
              
            </div> -->

            <a routerLinkActive="active-link" [ngStyle]="{'color': linkColor}" class="nav-link" *ngIf="isLoggedIn()" routerLink="/request/loggedin">Data</a>
          
          <a routerLinkActive="active-link" [ngStyle]="{'color': linkColor}" class="nav-link" *ngIf="!isLoggedIn()" routerLink="/request">Data</a>

        </li>
        <li class="nav-item">
          <a routerLinkActive="active-link" [ngStyle]="{'color': linkColor}" class="nav-link" routerLink="/media-press">Research</a>

        </li>
        <li class="nav-item" [ngClass]="{'show': isSupportMenuOpen}" style="position: relative;">
          <a [ngStyle]="{'color': linkColor}" class="nav-link dropdown-toggle" id="navbarDropdown" role="button"
             (click)="toggleSupportMenu()" aria-haspopup="true" aria-expanded="false">Support</a>
          <div class="dropdown-menu" [ngClass]="{'show': isSupportMenuOpen}" aria-labelledby="navbarDropdown" style="width: max-content;">
            <a class="dropdown-item" id="get-started-submenu" routerLink="/getting-started">Getting Started</a>
            <a class="dropdown-item" id="help-usage-submenu" routerLink="/user-guide">User Guide</a>
            <a class="dropdown-item" id="faq-submenu" routerLink="/faq">FAQ</a>
            <a class="dropdown-item" id="technical-support-submenu" routerLink="/technical-support">Technical Support</a>
          </div>
        </li>
        

        <li class="nav-item">
          <a [ngStyle]="{'color': linkColor}" class="nav-link" style="cursor: pointer;" (click)="scrollToElement('contactForm')">Contact</a>
        </li>
        <li class="nav-item d-lg-none" *ngIf="!isLoggedIn()">
          <a [ngStyle]="{'color': linkColor}" (click)="authenticationService.signIn()" class="nav-link">Sign In</a>
        </li>
      </ul>
    </div>

    <div  *ngIf="isLoggedIn()" style="width:184px; text-align: right;margin-top:18px;position: relative;" [ngClass]="{'show': isLoginMenuOpen}">
      <a [ngStyle]="{'color': linkColor}" class="nav-link dropdown-toggle" id="navbarUserDropdown" role="button"
        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="toggleUserMenu($event)">
        <fa-icon [icon]="faUser"></fa-icon>
      </a>
      <div class="dropdown-menu  p-0" [ngClass]="{'show': isLoginMenuOpen}" aria-labelledby="navbarUserDropdown" style="text-align: right;width: max-content;min-width: max-content;cursor: pointer;">
        <a class="dropdown-item" id="about-submenu-old1" routerLink="/profile/user">Profile</a>
        <a class="dropdown-item" id="dashboard-menu" *ngIf="isLoggedIn() && (isAdmin() || isApprover || isSupport())" (click)="navigateDashboard($event)">Dashboard</a>
        <a class="dropdown-item" id="contact-submenu" (click)="logout()">Logout</a>
        
      </div>
    </div>

    
    <button *ngIf="!isLoggedIn()" (click)="authenticationService.signIn()" class="btn btn-outline-primary ml-2 d-none d-lg-block aha-nav-btn" style="min-width: max-content;" [style.color]="isHomePage ? '#ffffff' : '#C10E21'"
      [style.borderColor]="isHomePage ? '#ffffff' : '#C10E21'" [ngClass]="{'btn-transparent-white': isHomePage, 'btn-transparent-red' : !isHomePage}" type="button">
      SIGN IN
    </button>
  </nav>
</header>

<app-pmp-banner-home *ngIf="showVideo"></app-pmp-banner-home>







<!-- <header class="header" [ngClass]="{'header--video': showVideo}">
    <ng-container *ngIf="showVideo">
        <video class="header__video" autoplay muted loop src="assets/video/homebanner-1024.mp4"></video>
      </ng-container>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="header__content">
                    <a class="navbar-brand" routerLink="/"><img class="header__logo" [src]="showVideo ? 'assets/images/aha-pmp-logo-white.png' : 'assets/images/aha-pmp-logo.png'" alt="American Heart Association"></a>
                    <nav class="header__nav ">
                    <a [ngStyle]="{'color': linkColor}" class="header__link" href="#features">Features</a>
                    <a [ngStyle]="{'color': linkColor}" class="header__link" href="#data">Data</a>
                    <img routerLink="/" class="header__center-logo" [src]="showVideo ? 'assets/images/precision-medicine-platform-white-logo.png' : 'assets/images/precision-medicine-platform-logo.png' " alt="Precision Medicine Platform">
                    <a [ngStyle]="{'color': linkColor}" class="header__link" href="#pricing">Pricing</a>
                    <a [ngStyle]="{'color': linkColor}" class="header__link" href="#history">History</a>
                    </nav>
                    <button class="header__signin ">Sign In</button>

                    <div class="header__overlay-text col-6" *ngIf="showVideo">
                        <div class="header__introtitle">The American Heart Association’s</div>
                        <div class="header__title">Precision Medicine Platform</div>
                        <div class="header__subtitle">Streamlined access to diverse data. Intuitive workspaces with the power of A.I.<br /> A game-changer for researchers.</div>
                        <div class="header__buttons">
                            <button class="header__button header__button--demo">Request a demo</button>
                            <button class="header__button header__button--signup">Sign up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header> -->


<!-- <mat-toolbar color="primary" class="d-lg-none">
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <img class="header__logo" src="assets/images/aha-pmp-logo-white.png" alt="Logo">
      </mat-toolbar>
      <mat-sidenav-container class="d-lg-none">
        <mat-sidenav #sidenav>
          <nav class="header__nav">
            <a [ngStyle]="{'color': linkColor}" class="header__link" href="#features">Features</a>
            <a [ngStyle]="{'color': linkColor}" class="header__link" href="#data">Data</a>
            <img class="header__center-logo" src="assets/images/precision-medicine-platform-white-logo.png" alt="Center Logo">
            <a [ngStyle]="{'color': linkColor}" class="header__link" href="#pricing">Pricing</a>
            <a [ngStyle]="{'color': linkColor}" class="header__link" href="#history">History</a>
          </nav>
          <button class="header__signin">Sign In</button>
        </mat-sidenav>
        <mat-sidenav-content>
        </mat-sidenav-content>
      </mat-sidenav-container>
  </header> -->


<!-- <div style="height: auto;">
<header>
        <div class="container container-fluid container-wide">
            <nav class="navbar navbar-expand-lg navbar-expand-md">
                <a class="navbar-brand" routerLink="/"><img src="assets/images/aha-pmp-logo.png" alt="Logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <img routerLink="/" class="pmp-logo-right" src="assets/images/precision-medicine-platform-logo.png" width="150" height="70">
                </div>
            </nav>
        </div>
    </header>
   

    
    
                </div> -->