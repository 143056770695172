<app-title-subtitle title="Technical Support"></app-title-subtitle>

<div class="container container-wide">

  <app-contact-box title="How can we help?" type="technicalSupport" theme="light"></app-contact-box>

</div>


<!-- <div class="container technical-support-container" style="padding-top: 10px;margin-top: 25px">
    <div *ngIf="message" id="message" class="alert technical-support-alert-info fade show alert-dismissable">
        <a href="#" class="close" data-bs-dismiss="alert" aria-label="close" title="close">x</a>
        {{message}}
    </div>
    <div *ngIf="errorMessage" id="errorMessage" class="alert alert-danger fade show">
        <a href="#" class="close" data-bs-dismiss="alert" aria-label="close" title="close">x</a>
        {{errorMessage}}
    </div>
    <p class="sub-heading" style="color: #CE1210">
      How can we help?
    </p>

  <form  id="technicalSupportForm" [formGroup]="technicalSupportForm" novalidate>
    <div class="row">
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
              <label>First Name:</label>
          </div>
          <div class="col-sm-9 col-9">
            <input formControlName="firstName" required class="form-control" [(ngModel)]="firstName" name="firstName" id="firstName" type="text" placeholder="First Name*"/>
            <div id="firstNameError" *ngIf="technicalSupportForm.controls['firstName'].invalid && (technicalSupportForm.controls['firstName'].dirty || technicalSupportForm.controls['firstName'].touched)" class="warning-text">
              <div *ngIf="technicalSupportForm.controls['firstName'].errors.required">
                First Name can not be empty
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
              <label>Last Name:</label>
          </div>
          <div class="col-sm-9 col-9">
              <input formControlName="lastName" required class="form-control" [(ngModel)]="lastName" name="lastName" id="lastName" type="text" placeholder="Last Name*"/>
              <div id="lastNameError" *ngIf="technicalSupportForm.controls['lastName'].invalid && (technicalSupportForm.controls['lastName'].dirty || technicalSupportForm.controls['lastName'].touched)" class="warning-text">
                <div *ngIf="technicalSupportForm.controls['lastName'].errors.required">
                  Last Name can not be empty
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
            <label>Email:</label>
          </div>
          <div class="col-sm-9 col-9">
            <input pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" formControlName="email" required class="form-control" [(ngModel)]="email" name="email" type="email" placeholder="Email*"/>
            <div *ngIf="technicalSupportForm.controls['email'].invalid && (technicalSupportForm.controls['email'].dirty || technicalSupportForm.controls['email'].touched)" class="warning-text">
              <div id="emailEmptyError" *ngIf="technicalSupportForm.controls['email'].errors.required">
                  EmailId can not be empty
              </div>
              <div id="emailValidError" *ngIf="technicalSupportForm.controls['email'].errors.pattern">
                  EmailId should be valid.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
            <label>Phone:</label>
          </div>
          <div class="col-sm-9 col-9">
            <input formControlName="phone" class="form-control" [(ngModel)]="phone" name="phone" id="phone" type="text" placeholder="Phone Number (Optional)"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
            <label>Organization:</label>
          </div>
          <div class="col-sm-9 col-9">
            <input formControlName="organization" class="form-control" [(ngModel)]="organization" name="organization" id="organization" type="text" placeholder="Organization (Optional)"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-6 input-col">
        <div class="row">
          <div class="col-sm-3 col-3">
            <label>Country:</label>
          </div>
          <div class="col-sm-9 col-9">
              <input formControlName="country" class="form-control" [(ngModel)]="country" name="country" id="country" type="text" placeholder="Country (Optional)"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-9 col-9 input-col">
        <div class="row">
          <div class="col-sm-2 col-2">
            <label>Message:</label>
          </div>
          <div class="col-sm-9 col-9">
            <textarea formControlName="queryDescription"  required class="form-control" id="queryDescription" name="queryDescription" type="text" [(ngModel)]="queryDescription" placeholder="Tell us how we can help*"></textarea>
            <div id="messageEmptyError" *ngIf="technicalSupportForm.controls['queryDescription'].invalid && (technicalSupportForm.controls['queryDescription'].dirty || technicalSupportForm.controls['queryDescription'].touched)" class="warning-text">
              <div *ngIf="technicalSupportForm.controls['queryDescription'].errors.required">
                Message can not be empty
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
        <div class="col-sm-6 col-6 input-col">
          <div class="row">
            <div class="col-sm-3 col-3">
              <label>Attachment:</label>
            </div>
            <div class="col-sm-9 col-9">
              <input formControlName="fileName" [(ngModel)]="fileName" class="form-control"  name="fileName" id="fileName" type="text" placeholder="Attachment (Optional), Max size 5 MB" readonly="true"/>
              <input type="file" id="fileToUpload"  #fileInput placeholder="Upload file..."  (change)="changeListener($event)"  style="display:none"/>
              <button id="chooseFileBtn" type="button" onclick="document.getElementById('fileToUpload').click();">Choose file</button>
              </div>
          </div>
        </div>
      </div>
    <div class="row" style="padding-top: 10px;margin-top: 25px">
        <div style="text-align: center">
          <button style="margin-right:15px;" class="btn btn-default btn-sm" id="cancelBtn" type="button" routerLink="/">Cancel</button>
          <button [disabled]="technicalSupportForm.pristine || technicalSupportForm.invalid" (click)="submitTechnicalSupportRequest()" class="btn btn-primary primary-btn" id="submittechnicalSupportFormBtn" type="button">Submit</button>
        </div>
    </div>
    
  </form>
</div> -->